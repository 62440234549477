// Rem output with px fallback
@mixin font-size($sizeValue: 1) {
	font-size: ($sizeValue * 16) * 1px;
	font-size: $sizeValue * 1rem;
}

@function size($val: 16px) {
	@return ($val / 16) * 1rem;
}

@mixin triangle($width, $color: black) {
	width: 0;
	height: 0;
	border: 0;
	padding: 0;
	background: transparent;
	border-left: $width solid transparent;
	border-right: $width solid transparent;
	border-bottom: $width solid $color;
}

@mixin pseudo() {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
}

@mixin absolute() {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

@mixin paddings {
	padding-top: var(--ptm);
	padding-bottom: var(--pbm);

	@include sm {
		padding-top: var(--ptt);
		padding-bottom: var(--pbt);
	}

	@include lg {
		padding-top: var(--ptd);
		padding-bottom: var(--pbd);
	}
}

@mixin margins {
	margin-top: var(--mtm);
	margin-bottom: var(--mbm);

	@include sm {
		margin-top: var(--mtt);
		margin-bottom: var(--mbt);
	}

	@include lg {
		margin-top: var(--mtd);
		margin-bottom: var(--mbd);
	}
}

@mixin wrapper {
	padding-left: 0;
	padding-right: 0;
	width: 85%;
	margin-left: auto;
	margin-right: auto;

	@include md {
		width: 85%;
	}

	@include lg {
		width: 80%;
	}
}

@mixin wrapper-single() {
	padding-left: 10%;
	padding-right: 10%;
	@include md {
		padding-left: 14%;
		padding-right: 14%;
	}
	@include lg {
		padding-left: 16%;
		padding-right: 16%;
	}
}

@mixin widen-single() {
	margin-left: -10vw;
	margin-right: -10vw;

	@include sm {
		margin-left: -5%;
		margin-right: -5%;
	}

	@include lg {
		margin-left: -5%;
		margin-right: -5%;
	}
}

@mixin input-text() {
	input[type='text'],
	input[type='email'],
	input[type='url'],
	input[type='password'],
	input[type='search'],
	input[type='number'],
	input[type='tel'],
	input[type='range'],
	input[type='date'],
	input[type='month'],
	input[type='week'],
	input[type='time'],
	input[type='datetime'],
	input[type='datetime-local'],
	input[type='color'],
	textarea {
		@content;
	}
}

@include input-text() {
	padding: 3px;
	font-size: 1rem;
	line-height: 1rem;
	width: 100%;
	margin: 0;
	border: none;
	border-radius: size(7);
	box-shadow: inset 0px 3px 9px rgba(0, 0, 0, 0.14), 2px 2px 4px rgba(0, 0, 0, 0.35);
	&:focus {
		box-shadow: inset 0px 3px 9px rgba(0, 0, 0, 0.1), 2px 2px 4px rgba(0, 0, 0, 0.35);
	}
	@include lg {
		font-size: size(20);
		padding: size(5);
	}
}
select {
	padding: 3px;
	font-size: 1rem;
	line-height: 1rem;
	width: 100%;
	margin: 0;
	border: none;
	border-radius: size(7);
	box-shadow: inset 0px 3px 9px rgba(0, 0, 0, 0.14), 2px 2px 4px rgba(0, 0, 0, 0.35);
	&:focus {
		box-shadow: inset 0px 3px 9px rgba(0, 0, 0, 0.1), 2px 2px 4px rgba(0, 0, 0, 0.35);
	}

	@include lg {
		font-size: size(20);
		padding: size(5);
	}
}

form.search-form label {
	width: 100%;
}

form {
	button {
		// align-self: center;
	}
}

textarea {
	width: 100%;
}

.gform_wrapper form {
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
}
.gform_wrapper form .gform_fields {
	grid-row-gap: size(1);
	.gfield {
		label.gfield_label {
			font-weight: 400;
			margin-bottom: 0;
			font-size: size(13);
			line-height: 1;
		}
		@include input-text() {
			padding: 3px;
			font-size: 1rem;
			line-height: 1rem;
			width: 100%;
			margin: 0;
			border: none;
			border-radius: size(7);
			box-shadow: inset 0px 3px 9px rgba(0, 0, 0, 0.14), 2px 2px 4px rgba(0, 0, 0, 0.35);
			&:focus {
				box-shadow: inset 0px 3px 9px rgba(0, 0, 0, 0.1), 2px 2px 4px rgba(0, 0, 0, 0.35);
			}
			@include lg {
				font-size: size(20);
				padding: size(5);
			}
		}
	}
}

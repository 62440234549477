button,
input[type='button'],
input[type='reset'],
input[type='submit'],
.btn {
	background: $red;
	text-decoration: none;
	border: none;
	border-radius: size(7);
	box-shadow: 2px 2px 4px rgba(80, 0, 0, 0.35);
	font-size: size(20);
	line-height: size(37);
	padding: 0 size(25);
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: size(1);
	color: $eggshell;
	display: inline-block;
	transition: 200ms all;
	border: 3px solid $red;
	cursor: pointer;

	&:hover,
	&:focus {
		color: $red;
		background: transparent;
		box-shadow: inset 2px 2px 4px rgba(80, 0, 0, 0.35), 2px 2px 8px transparentize($gray, 0.7);
		text-decoration: none;
	}

	@include lg {
		font-size: size(18);
		padding: 0 size(40);
	}

	&.btn-light,
	.light-text & {
		background: $eggshell;
		color: $red;
		border-color: $eggshell;
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);

		&:hover,
		&:focus {
			background: $red;
			color: $eggshell;
			box-shadow: inset 2px 2px 4px rgba(0, 0, 3px, 0.3), 2px 2px 4px rgba(0, 0, 0, 0.1),
				2px 2px 7px rgba(0, 0, 0, 0.3);
		}
	}
}

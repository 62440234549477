@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$font: 'Montserrat', sans-serif;
$font__code: Monaco, Consolas, 'Andale Mono', 'DejaVu Sans Mono', monospace;

$red: #8a2a2b;
$red_bright: #a71e25;
$blue: #65b4bd;
$brightblue: #4bb6c0;
$blue_light: #e5f2f1;
$lightgray: #bcbcbc;
$gray: #4b4b4b;
$text: #000;
$lightgray: #f5f5f5;
$eggshell: #fffcfa;
$pink: #ffdfce;

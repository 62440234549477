html {
	box-sizing: border-box;
	max-width: 100vw;
	overflow-x: hidden;
}

*,
*:before,
*:after {
	/* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
	box-sizing: inherit;
}

body {
	background: #fff; /* Fallback for when there is no custom background color defined. */
	overflow-x: hidden;
	max-width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
	main.main {
		flex-grow: 1;
		background-color: $eggshell;
		width: 100vw;
		max-width: 100vw;
		position: relative;
		@include s1920 {
			max-width: 1920px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

img {
	height: auto;
	max-width: 100%;
}

.inner-wrapper {
	width: 85%;
	margin-left: auto;
	margin-right: auto;

	@include md {
		width: 85%;
	}

	@include lg {
		width: 80%;
	}
}

.four-oh-four {
	text-align: center;
	padding-top: 100px;
	padding-bottom: 200px;
	h1 {
		font-size: size(40);
	}
	@include sm {
		h1 {
			font-size: size(70);
		}
	}
}

html {
	font-size: 16px;
	font-family: $font;

	@include xxl {
		font-size: 17px;
	}
}

body {
	font-weight: 400;
	font-size: size(13);
	line-height: 1.6;
	font-family: $font;
	color: $text;
	@include md {
		font-size: size(15);
	}
	@include lg {
		font-size: size(16);
	}
	@include xl {
		font-size: size(18);
	}
}

p {
	margin: 1rem 0;
}

dfn,
cite,
em,
i {
	font-style: italic;
}

pre {
	background: #eee;
	font-family: 'Courier 10 Pitch', Courier, monospace;
	max-width: 100%;
	overflow: auto;
}

abbr,
acronym {
	cursor: help;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
	font-weight: 700;
	text-transform: uppercase;
	color: $gray;
	margin-top: 0;
	margin-bottom: 1rem;
}

h1,
.h1 {
	font-size: size(28);
	font-weight: 700;
	line-height: 1.2;
	@include lg {
		font-size: size(38);
	}
	@include xl {
		font-size: size(48);
	}
}

h2,
.h2 {
	font-size: size(24);
	line-height: 1.3;
	@include lg {
		font-size: size(32);
	}
	@include xl {
		font-size: size(40);
	}
}

h3,
.h3 {
	font-size: size(20);
	line-height: 1.25;
	@include lg {
		font-size: size(26);
	}
	@include xl {
		font-size: size(32);
	}
}

h4,
.h4 {
	font-size: size(17);
	line-height: 1.3;
	@include lg {
		font-size: size(24);
	}
}

p {
	margin: 0;
	margin-bottom: 1rem;
}

.quote {
	font-weight: 600;
	font-style: italic;
	text-transform: uppercase;
	font-size: size(17);
	line-height: 1.3;
	@include lg {
		font-size: size(20);
	}
}

.p-heading {
	color: $red;
	font-size: size(16);
	line-height: 1.25;
	margin: 0 0 1rem 0;
	@include lg {
		font-size: size(24);
	}
}

a {
	color: $red;
	text-decoration: none;
	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.small,
.caption {
	font-size: size(13);
}

.light-text {
	color: $eggshell;
	color: $eggshell;
	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	.h1,
	.h2,
	.h3,
	.h4,
	.h5 {
		color: $eggshell;
	}
}

.small-title {
	font-size: size(13);
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 0;
	@include lg {
		font-size: size(15);
	}
}

//
// MEDIA QUERIES
//_________________________________________________________

// Map of breakpoints.
$breakpoints: (
	xs: 567px,
	sm: 768px,
	md: 990px,
	lg: 1200px,
	xl: 1440px,
);

@mixin s400 {
	@media (min-width: 400px) {
		@content;
	}
}

@mixin xs {
	@media (min-width: 576px) {
		@content;
	}
}

@mixin sm {
	@media (min-width: 768px) {
		@content;
	}
}

@mixin md {
	@media (min-width: 1020px) {
		@content;
	}
}

@mixin lg {
	@media (min-width: 1280px) {
		@content;
	}
}

@mixin xl {
	@media (min-width: 1440px) {
		@content;
	}
}

@mixin xxl {
	@media (min-width: 1600px) {
		@content;
	}
}

@mixin s1920 {
	@media (min-width: 1920px) {
		@content;
	}
}

// MEDIA BREAKPOINT UP

// @include breakpoint-up(sm) {}
@mixin breakpoint-up($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		$breakpoint-value: map-get($breakpoints, $breakpoint);

		@media (min-width: $breakpoint-value) {
			@content;
		}
	} @else {
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

@mixin breakpoint-down($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		$breakpoint-value: map-get($breakpoints, $breakpoint);

		@media (max-width: $breakpoint-value) {
			@content;
		}
	} @else {
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

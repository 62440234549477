@import 'base/variables';
@import 'base/media-queries';
@import 'base/mixins';
@import 'base/global';

body.login {
	background: url('../../images/login-screen-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	@import 'base/fonts';
	@import 'forms/buttons';
	@import 'forms/fields';

	#login {
		background-color: white;
		margin: 8% auto 0;
		padding: 0 0 1%;
		font-family: $font;
		@include sm {
			width: 41%;
		}
		@include md {
			width: 35%;
		}
		@include lg {
			width: 30%;
		}
		@include xl {
			width: 27%;
		}
		a {
			color: $red;
			&:hover,
			&:focus {
				text-decoration: underline;
				color: $red;
			}
		}
		h1 a {
			background-image: url('../../images/rx360-logo.png');
			background-size: contain;
			background-position: center;
			width: 150px;
		}

		form {
			border: none;
			label {
				font-weight: 600;
				font-size: size(16);
			}
			@include input-text() {
				margin-bottom: 1rem;
				box-shadow: inset 2px 3px 10px rgba(0, 0, 0, 0.1),
					inset 2px 3px 5px rgba(0, 0, 0, 0.05), 2px 2px 8px rgba(0, 0, 0, 0.1),
					2px 2px 12px rgba(0, 0, 0, 0.1);
				&:focus {
					border: 2px solid $red_bright;
				}
			}
			.dashicons {
				&:before {
					color: $red;
					// opacity: 0.9;
				}
				&:hover,
				&:focus {
					&:before {
						color: $red_bright;
						opacity: 1;
					}
				}
			}
			p {
				width: 100%;
			}
			p.submit {
				text-align: center;
			}
			input[type='submit'] {
				font-size: size(14);
				background-color: $red_bright;
				border-color: $red_bright;
				margin-left: auto;
				margin-right: auto;
				float: none;
				text-align: center;
				margin-top: 1rem;
				&:hover,
				&:focus {
					background: $eggshell;
					color: $red_bright;
				}
			}
			p.forgetmenot {
				display: flex;
				align-items: center;
				input {
					margin: 0;
					margin-right: 0.4rem;
				}
				label {
					margin: 0;
					font-size: size(13);
					font-weight: 400;
				}
			}
		}
	}
}
